<template>
  <div class="mintbox content">
    <div class="logo">
      <img class="logoImg" @click="lanageShow = true" src="../../src/static/logo.png" alt="" srcset="" />
      <!-- <img
        class="lianjieBox"
        src="../../src/static/address.png"
        alt=""
        srcset=""
      /> -->
      <div class="lianjie">
        <span v-if="!userAddress">{{Language[selectLan]["连接"]}}</span>
		<span v-if="userAddress">{{userAddress.substring(0,10)+'...'+userAddress.substring(36)}}</span>
        <div class="quan" v-if="!userAddress">
          <img src="../../src/static/youshang.png" alt="" srcset="">
        </div>
      </div>
    </div>
    <div class="addressBox">
      <div class="btns">
        <span @click="activeIndex = 0" :class="activeIndex == 0 ? 'active' : ''"
          >{{Language[selectLan]["激活白名单"]}}</span
        >
        <span @click="activeIndex = 1" :class="activeIndex == 1 ? 'active' : ''"
          >{{Language[selectLan]["铸造"]}}</span
        >
      </div>
      <div class="title">{{Language[selectLan]["绑定推荐地址"]}}：</div>
      <div class="input" v-if="activeIndex == 0">
		<input type="text" class="mintInput" disabled :value="getrefMap[0]" v-if="activeIndex == 0 &&(getrefMap[0]!=address0||userAddress==param[4])"/>
        <input type="text" class="mintInput" v-model="bandAddress" v-else/>
      </div>

	  <div class="inputs" v-if="activeIndex == 1">
		<div class="blackBox" style="width:30%;margin-right:10px">
			{{getrefMap[0]!=address0?Language[selectLan]["白名单"]:Language[selectLan]["未激活"]}}
		</div>
		<div class="blackBox" style="    width: 70%;display: flex;align-items: center;justify-content: space-between;padding-right: 20px;">
			<div style="    display: flex;align-items: center;">{{Language[selectLan]["可铸造数量"]}} <span class="xian"></span></div>
			

			<span class="num">
				<a-dropdown>
					<div class="ant-dropdown-link" @click.prevent>
						{{mintAmount}}
						<img style="width:8px;margin-left:5px" src="../static/xia.png" alt="" srcset="">
					</div>
					
					<template #overlay v-if="getrefMap3[2]>0">
					  <a-menu>
						<a-menu-item v-for="(item,index) in mintAmounts0">
							<span @click="changeMintAmount(item)">{{item}}</span>
						</a-menu-item>
					  </a-menu>
					</template>
					<template #overlay v-if="getrefMap3[2]==0">
					  <a-menu>
						<a-menu-item v-for="(item,index) in mintAmounts">
							<span  @click="changeMintAmount(item)">{{item}}</span>
						</a-menu-item>
						
					  </a-menu>
					</template>
				  </a-dropdown>
			</span>
		</div>
      </div>
    </div>

    <div class="sureBtn" style="background-color: darkgray;" v-if="activeIndex == 0 &&(getrefMap[0]!=address0||userAddress==param[4])">{{Language[selectLan]["已绑定"]}}</div>
	<div class="sureBtn" @click="band" v-if="activeIndex == 0&&(getrefMap[0]==address0&&userAddress!=param[4])">{{Language[selectLan]["绑定"]}}</div>
	
	<div class="sureBtn" v-if="activeIndex == 1 && getrefMap[4]==0&&getrefMap2[5]==0 &&mintEnable<=2&&getrefMap[0]!=address0" @click="mint()">{{Language[selectLan]["铸造"]}}</div>
	<div class="sureBtn" v-if="activeIndex == 1 &&(getrefMap[4]!=0||getrefMap2[5]!=0)&&mintEnable<=2&&getrefMap[0]!=address0" style="background-color: darkgray;">{{Language[selectLan]["我已铸造"]}}</div>
	<div class="sureBtn" v-if="activeIndex == 1 &&mintEnable<=2&&getrefMap[0]==address0" style="background-color: darkgray;">{{Language[selectLan]["未绑定"]}}</div>
	<div class="sureBtn" v-if="activeIndex == 1 &&mintEnable>2" style="background-color: darkgray;">{{Language[selectLan]["已铸造完成"]}}</div>
	
	<div class="sureBtn" v-if="getrefMap3[1]>0&&mintEnable<4" @click="divieBnb">{{Language[selectLan]["领取推荐奖励"]}}：{{getrefMap3[1]}}BNB</div>

    <div class="lineBox">
      <div class="line"></div>
      <span>Welcome to PMM</span>
    </div>

    <div class="descBox" style="min-height: 350px;">
      <!-- <div class="nowhite">
        你還不是白名單用戶，先去官方電報群裏獲取白名單資格吧！ 官方電報群
        @xxxxxx
      </div> -->
      <div class="jihuo" >
        <div class="name" v-if="getrefMap[0]!=address0||userAddress==param[4]">{{Language[selectLan]["自身已激活"]}}</div>
		<div class="name" v-if="getrefMap[0]==address0&&userAddress!=param[4]">{{Language[selectLan]["自身未激活"]}}</div>
        <div class="numberBox" v-if="getrefMap[0]!=address0||userAddress==param[4]">
          <!-- <span>{{shareUrl + "?pid=" + userAddress}}</span> -->
		  <span>{{shareUrl + "?..."}}</span>
		  
          <span @click="copyUrl">{{Language[selectLan]["复制URL"]}}</span>
        </div>
      </div>


	  <!-- <div class="videoAddress">
		<div class="list">
			<div>{{Language[selectLan]["支付"]}}直推已激活地址数：</div>
			<div  style="color:#fff">{{getrefMap[1]}}</div>
		</div>
		<div class="list">
			<div>{{Language[selectLan]["支付"]}}团队已激活地址数：</div>
			<div  style="color:#fff">{{getrefMap[2]}}</div>
		</div>
	  </div> -->
    </div>
	
	<tab :pageActive="2"></tab>
	
	<lang v-if="lanageShow" :Language="Language" @checkClose="checkClose" :selectLan="selectLan" @selectLanague="selectLanague"></lang>
  </div>
</template>

<!-- lang="ts" -->
<script>
import Web3 from "web3";
import MyContractABI from "../abi/main.json"; // 导入合约 ABI
import { message } from "ant-design-vue";
import Language from "../lan/lan.json"; //
import tab from "../../src/components/tab.vue";
import Spin from '@/utils/spinLoading.js'
import lang from "../../src/components/lang.vue";

export default {
  // computed: {
  //   author () {
  //     return process.env.VUE_APP_AUTHOR;
  //   },
  //   apiBaseUrl () {
  //     return process.env.VUE_APP_API_BASE_URL;
  //   }
  // }
  components:{
    tab,lang
  },
  data() {
    return {
      activeIndex: 0,
      defaultProps: {
        children: "children",
        label: "label",
      },
      //测试环境
      contractAddress: "0xc07cfe20bf28454c5704fa183f4337fdbf2e5d41",
      //networkId:97,
	  networkId:56,
	  //mintAmounts:[0.0001,0.0002], //测试环境
	  //mintAmounts0:[0.0003],
	  
	  //contractAddress: "0x541f3f0f7588d073842524c0482e267b666b97b2a38fbf21ac77685e99e71e3f",
      //networkId: 56,
	  mintAmounts: [0.1, 0.2], //正式环境
	  mintAmounts0:[0.3],
      //end

      userAddress: "",
      dataStat: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
      tokenName: "PMM",
      mintAmount: 0,
      price: 0,
	  mintEnable:0,
      loaded: false,
      dataStat2: [0, 0, 0, 0, 0, 0, 0, 0],
      dataStat3: [this.address0,this.address0,this.address0, 0,0,0,],
      getrefMap: [this.address0,0,0,0,0,0,0,],
      getrefMap2: [0, 0, 0],
      param: [0, 0,0,this.address0,this.address0,0,0,0,0,0,],
	  address0:'0x0000000000000000000000000000000000000000',
	  getrefMap3:[0,0,0],
      exchangeAmount: 0,
      data2: {},
      minTransferCount: 0,
      bnbBalance: 0,
      web3: {},
      tokenBalance: 0,
      bandAddress: "",
      burnAmount: 0,
      times: 3,
      burnAmountR: 0,
	  Language: Language,
	  selectLan: "en",
	  lanageShow: false,
      shareUrl: "https://bscpmmbox.github.io/pmm",
      //shareUrl:'http://localhost:8080',
    };
  },
  created() {
    if (localStorage.getItem("language")) {
      this.selectLan = localStorage.getItem("language");
    }
    if (this.$route.query.pid) {
      this.bandAddress = this.$route.query.pid;
    }
    this.connectBSC();
    let that = this;
    this.interval = setInterval(function () {
      that.loadData();
    }, 1000 * 15);
  },
  destroyed() {
    if (this.interval) {
      clearInterval(this.interval);
    }
  },
  methods: {
	  checkClose(key){
	  		  this.lanageShow = false;
	  },
	  selectLanague(key){
	  		  this.selectLan = key;
	  		  this.lanageShow = false;
	  		  localStorage.setItem("language", this.selectLan);
	  },
	  changeMintAmount(value){
		this.mintAmount = value;  
	  },
    copyUrl() {
      var textarea = document.createElement("textarea");
      textarea.style.position = "fixed";
      textarea.style.opacity = 0;
      //  if(location.href.indexOf("?")!=-1){
      // textarea.value = location.href.substring(0,location.href.indexOf("?")) +'?pid='+this.userAddress;
      //  }else{
      // textarea.value = location.href+'?pid='+this.userAddress;
      //  }
      textarea.value = this.shareUrl + "?pid=" + this.userAddress;
      document.body.appendChild(textarea);
      textarea.select();
      document.execCommand("copy");
      document.body.removeChild(textarea);
      message.success("success copy");
    },
    async burn() {
      if (!this.burnAmount) {
        message.error("please enter amount!");
        return;
      }
      if (parseFloat(this.burnAmount + "") > this.tokenBalance) {
        message.error("balance is not enough");
        return;
      }
      if (parseFloat(this.burnAmount + "") < this.minTransferCount) {
        message.error("need " + this.minTransferCount);
        return;
      }

      const value = this.web3.utils.toWei(this.burnAmount + "", "ether"); // 以太转账金额，这里以1以太为例
      this.contract.methods
        .burn(value)
        .send({ from: this.userAddress })
        .on("transactionHash", (hash) => {
          // 在交易被发送到以太坊网络时触发的事件
          message.success("submit success");
          console.log("交易哈希:", hash);
        })
        .on("receipt", (receipt) => {
          // 在交易被打包后确认时触发的事件
          console.log("交易收据:", receipt);
          //message.success(receipt.transactionHash);
          message.success("transaction success");
          this.loadData();
        })
        .on("error", (error) => {
          // 在交易失败时触发的事件
          //message.error(error);
          console.error("交易失败:", error);
        });

      //await this.contract.methods.band(this.bandAddress).call({});
      //message.success('success!');
    },
    maxBurn() {
      this.burnAmount = this.tokenBalance;
    },
    back() {
      this.$router.replace("/?notTo=1");
    },
    async band() {
      if (!this.bandAddress || this.bandAddress.length < 42) {
        message.error("please enter valid address!");
        return;
      }
      if (this.bandAddress.toLowerCase() == this.userAddress) {
        message.error("address error");
        return;
      }
	  if(this.bandAddress.toLowerCase() != this.param[4].toLowerCase()){ //等于创世节点
			
		  // let getrefMap2 = await this.contract.methods
		  //   .getrefMap2(this.bandAddress)
		  //   .call({});
		  // if (parseFloat(getrefMap2[2] + "") <= 0) {
		  //   message.error("please enter valid address!");
		  //   return;
		  // }
		  
		  let getrefMap = await this.contract.methods
		    .getrefMap(this.bandAddress)
		    .call({});
		  if (getrefMap[0] == this.address0) {
		    message.error("please enter valid address!");
		    return;
		  }
	  }

      this.contract.methods
        .band(this.bandAddress)
        .send({ from: this.userAddress })
        .on("transactionHash", (hash) => {
          // 在交易被发送到以太坊网络时触发的事件
          message.success("submit success");
          console.log("交易哈希:", hash);
        })
        .on("receipt", (receipt) => {
          // 在交易被打包后确认时触发的事件
          console.log("交易收据:", receipt);
          //message.success(receipt.transactionHash);
          message.success("transaction success");
          this.loadData();
        })
        .on("error", (error) => {
          // 在交易失败时触发的事件
          //message.error(error);
          console.error("交易失败:", error);
        });

      //await this.contract.methods.band(this.bandAddress).call({});
      //message.success('success!');
    },
	async divieBnb() {
	  this.contract.methods
	    .divieBnb()
	    .send({ from: this.userAddress })
	    .on("transactionHash", (hash) => {
	      // 在交易被发送到以太坊网络时触发的事件
	      message.success("submit success");
	      console.log("交易哈希:", hash);
	    })
	    .on("receipt", (receipt) => {
	      // 在交易被打包后确认时触发的事件
	      console.log("交易收据:", receipt);
	      //message.success(receipt.transactionHash);
	      message.success("transaction success");
	      this.loadData();
	    })
	    .on("error", (error) => {
	      // 在交易失败时触发的事件
	      //message.error(error);
	      console.error("交易失败:", error);
	    })
	},
	
    mint() {
      if (!this.mintAmount) {
        message.error("please select amount!");
        return;
      }
	  if(this.getrefMap2[1] == 1){ //普通白名单
		  if (parseFloat(this.mintAmount) > this.param[6]) {
		    message.error("mint max " + this.param[6]);
		    return;
		  }
		  if (this.mintAmount > this.param[2] || this.mintAmount < this.param[1]) {
		    message.error("mint " + this.param[1] + "-" + this.param[2]);
		    return;
		  }
		  
		  if (
		    parseFloat(this.getrefMap[4]) + parseFloat(this.mintAmount) >
		    this.param[2]
		  ) {
		    message.error("mint max " + this.param[2]);
		    return;
		  }
	  }
		console.log(this.mintAmount,'this.mintAmount');
      const value = this.web3.utils.toWei(this.mintAmount + "", "ether"); // 以太转账金额，这里以1以太为例

      this.web3.eth.sendTransaction(
        {
          from: this.userAddress,
          to: this.contractAddress,
          value: value,
        },
        (error, transactionHash) => {
          if (!error) {
            //message.error('error:'+transactionHash);
            message.success("success!");
            this.loadData();
            console.log(transactionHash); // 交易哈希
          } else {
            //message.error(error);
            console.error(error);
          }
        }
      );
    },
    max() {
      this.mintAmount = this.param[2] - this.getrefMap[4];
    },
    async connectBSC() {
      // if (window.ethereum) {
      //     await window.ethereum.request({method: 'eth_requestAccounts'});
      //     window.web3 = new Web3(window.ethereum);
      //     return true;
      //   }
      let that = this;

      if (window.ethereum) {
        try {
          window.ethereum.on("accountsChanged", function (accounts) {
            // accounts 是一个数组，包含当前连接的所有账户
            console.log("change address:", accounts[0]);
            that.connectBSC();
            // 在这里处理账户变更后的逻辑
            // 例如更新界面上显示的账户信息等
          });
          console.log(window.ethereum, "window.ethereum");
          // 请求用户授权连接到BSC
          const accounts = await window.ethereum.request({
            method: "eth_requestAccounts",
          });

          console.log(accounts, "accounts");

          // 创建web3实例
          this.web3 = new Web3(window.ethereum);

          // 获取用户地址
          this.userAddress = accounts[0].toLowerCase(); // 更新用户地址

          let bnbBalance = await this.web3.eth.getBalance(this.userAddress);
          this.bnbBalance = this.web3.utils.fromWei(bnbBalance, "ether");

          // 加载合约
          console.log(MyContractABI, "MyContractABI");
          const networkId = await this.web3.eth.net.getId();
          //const deployedNetwork = MyContractABI.networks[networkId];
          if (networkId != this.networkId) {
            message.error("err network，please select BNB chain");
          }
          this.contract = new this.web3.eth.Contract(
            MyContractABI,
            // deployedNetwork && deployedNetwork.address
            this.contractAddress
          );

          this.connected = true;

          this.loadData();
        } catch (error) {
          console.error(error);
        }
      } else {
        // alert("Please install MetaMask or a BSC-compatible wallet to use this DApp.");
      }
    },
    async loadData() {
      if (this.contract) {
        try {
          // 调用合约方法
		  if(!this.loaded){
				Spin.show('Loading');
		  }
          let dataStat = await this.contract.methods.getStatData().call({});
		  this.mintEnable= parseInt(dataStat[8]+'');
          let dataStat2 = await this.contract.methods.getStatData2().call({});
          let param = await this.contract.methods.getParam().call({});
		  console.log(param,'paramold');
          let getrefMap = await this.contract.methods
            .getrefMap(this.userAddress)
            .call({});
          let getrefMap2 = await this.contract.methods
            .getrefMap2(this.userAddress)
            .call({});
			let getrefMap3 = await this.contract.methods
			  .getrefMap3(this.userAddress)
			  .call({});
			 console.log(getrefMap3,'getrefMap3old');
          let balanceOf = await this.contract.methods
            .balanceOf(this.userAddress)
            .call({});
          
          let burnAmountR = await this.contract.methods
            .burnAmountR(this.userAddress)
            .call({});
          this.burnAmountR = parseFloat(
            this.web3.utils.fromWei(burnAmountR, "ether")
          );

          // let allUsersV0 = await this.contract.methods.allUsersV(4).call({});
          // console.log(allUsersV0,'allUsersV0');
          // let burnAmountS0 = await this.contract.methods.burnAmountS(this.userAddress).call({});
          // console.log(burnAmountS0,'burnAmountS0');

          balanceOf = this.web3.utils.fromWei(balanceOf, "ether");

          param[1] = this.web3.utils.fromWei(param[1], "ether");
          param[2] = this.web3.utils.fromWei(param[2], "ether");
          param[3] = param[3].toLowerCase();
          param[4] = param[4].toLowerCase();
          param[5] = this.web3.utils.fromWei(param[5], "ether");
          param[6] = this.web3.utils.fromWei(param[6], "ether");
          getrefMap[6] = this.web3.utils.fromWei(getrefMap[6], "ether");
          getrefMap[5] = this.web3.utils.fromWei(getrefMap[5], "ether");
          getrefMap[4] = this.web3.utils.fromWei(getrefMap[4], "ether");
		  getrefMap[4] = parseFloat(getrefMap[4]);

          dataStat2[0] = this.web3.utils.fromWei(dataStat2[0], "ether");
          dataStat2[1] = this.web3.utils.fromWei(dataStat2[1], "ether");
			
		  getrefMap2[5] = this.web3.utils.fromWei(getrefMap2[5], "ether");
          getrefMap2[2] = parseInt(getrefMap2[2]);
		  getrefMap2[1] = parseInt(getrefMap2[1]);
		  getrefMap2[5] = parseFloat(getrefMap2[5]);
		  
		  getrefMap3[1] = this.web3.utils.fromWei(getrefMap3[1], "ether");
		  getrefMap3[1] = parseFloat(getrefMap3[1]);
		  getrefMap3[2] = parseFloat(getrefMap3[2]);
		  if(!this.mintAmount ){
			if(getrefMap3[2]==1){
			  this.mintAmount = this.mintAmounts0[0];
			}else{
			  this.mintAmount = this.mintAmounts[1];
			}
		  }

          this.dataStat = dataStat;
          this.dataStat2 = dataStat2;
          this.param = param;
          this.getrefMap = getrefMap;
          this.getrefMap2 = getrefMap2;
		  this.getrefMap3 = getrefMap3;

          this.tokenBalance = parseFloat(balanceOf);

		  if(!this.loaded){
		      this.loaded = true;
			  Spin.hide();
		  }
          console.log(this.dataStat, "dataStat:");
          console.log(this.dataStat2, "dataStat2:");
          console.log(this.param, "param:");
          console.log(this.getrefMap, "this.getrefMap");
		  console.log(this.getrefMap2, "this.getrefMap2");
		  console.log(this.getrefMap3, "this.getrefMap3");
        } catch (error) {
          console.error(error);
        }
      } else {
        console.error("Contract not initialized.");
      }
    },
  },
};
</script>


<style scope>
.mintbox {
  box-sizing: border-box;
}

.mintbox .logo {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 20px;
}

.logoImg {
  width: 112px;
  height: auto;
}

.lianjieBox {
  width: 152px;
  height: auto;
}

.addressBox {
  width: 95%;
  height: 193px;
  background-image: url("../static/huibg.png");
  background-size: 100% 100%;
  box-sizing: border-box;
  padding: 10px 20px;
  font-size: 12px;
  position: relative;
  margin-top: 30px;
}

.mintInput {
  background: #000000;
  box-sizing: border-box;
  border: 1px solid;
  border-image: linear-gradient(
      261deg,
      #5a5a5a 4%,
      rgba(138, 138, 138, 0.3767) 25%,
      rgba(231, 231, 231, 0.7) 68%
    )
    1;
  border-radius: 50px;
  width: 90%;
  height: 35px;
  outline: none;
  border: none;
  color: #fff;
  text-align: center;
  font-size: 14px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.btns {
  position: absolute;
  right: 0;
  top: -10px;
  display: flex;
  align-items: center;
}

.btns .active {
  background: #f5b22f;
  color: #000;
}

.btns span {
  height: 27px;
  border-radius: 196.5px;
  padding: 0 22px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #ffffff40;
  color: #fff;
  margin-left: 12px;
}

.sureBtn {
  width: 235px;
  height: 34px;
  border-radius: 20px;
  opacity: 1;

  background: #f5b22f;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 15px;
  font-weight: 600;
  text-align: center;
  color: #000000;
  margin-top: 30px;
}

.lineBox {
  display: flex;
  align-items: center;
  width: 95%;
  margin: 0 auto;
  white-space: nowrap;
  margin-top: 60px;
  font-size: 10px;
  color: #FFFFFF60;
}

.descBox {
  width: 95%;
  margin: 0 auto;
  margin-top: 20px;
}

.jihuo .name {
  text-align: center;
  font-size: 14px;
  font-weight: normal;
  color: #f5b22f;
}

.numberBox {
  width: 100%;
  height: 34px;
  background: #1d1c1f;
  box-sizing: border-box;
  border-image: linear-gradient(180deg, #070707 0%, #2f3138 98%) 2;
  color: rgba(255, 255, 255, 0.7);
  font-size: 12px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 15px;
  border-radius: 30px;
  margin-top: 20px;
}

.nowhite {
  font-size: 12px;
  font-weight: normal;
  line-height: 18px;
  text-align: center;
  color: #ffffff70;
  width: 90%;
  margin: 0 auto;
}

.line {
  width: 75%;
  height: 1px;
  background: #ffffff70;
  margin-right: 13px;
}

.videoAddress{
	background: rgba(255, 255, 255, 0.2);
	width: 95%;
	border: 0.5px solid #F5B22F;

backdrop-filter: blur(10px);
height: 56px;
margin: 0 auto;
margin-top: 40px;
border-radius: 10px;
display: flex;
align-items: center;
}

.inputs{
	display: flex;
	align-items: center;
	justify-content: space-between;
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%,-50%);
	width: 90%;
}

.blackBox{
	width: 121px;
height: 35px;
border-radius: 14px;
background: #1D1C1F;
box-sizing: border-box;
border-image: linear-gradient(180deg, #070707 0%, #2F3138 98%) 2;
color: #fff;

padding-left: 15px;
display: flex;
align-items: center;
position: relative;
}

.xian{
	width: 1px;
    height: 12px;
    display: block;
    background: #ffffff60;
    margin: 0 10px;
	
}

.videoAddress .list{
 width: 50%;
 padding-left: 10px;
 font-size: 13px;color: #F5B22F;
} 

body {
  background-image: url("../static/bg.png");
  background-size: 100% 100%;
}
.content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.second {
  width: 90%;
  margin: 10px auto;
  display: block;
  border-radius: 20px;
  border: 3px solid #4f82b4;
  background: rgba(14, 12, 32, 0.5);
}

.second01 {
  width: 90%;
  margin: auto;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 10px 0;
}

.second02 {
  font-size: 32px;
  color: white;
  font-size: 24px;
}

.second03 {
  width: 40px;
  height: 40px;
}

.second04 {
  width: 90%;
  margin: 18px auto;
  height: 180px;
  display: block;
  border-radius: 12px;
  border: 2x solid #5f7fb9;
  background: var(--Base-Black, #0e0c20);
  margin-top: 0;
}

.second04 textarea {
  outline: none;
  height: 100%;
}

.second05 {
  width: 90%;
  margin: 25px auto;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

select {
  outline: none;
}

.second09 {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 39px;
  height: 25px;
  border-radius: 6px;
  text-align: center;
  line-height: 20px;
  background-color: #497dbd;
  color: #fff;
  font-size: 12px;
  margin-left: 10px;
}

input {
  outline: none;
}



.lianjie{
  width: 152px;
  height: 33px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 50px;
  padding: 0 5px 0 15px;
  border: 1px solid #fff;
  color: #fff;
  font-size: 12px;
}

.lianjie .quan{
  width: 22px;
  height: 22px;
  border-radius: 50%;
  background: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
}

.lianjie .quan img{
  width: 17px;
  height: auto;
}
</style>